// Header.js
import React from 'react';

function Header() {
  return (
    <header className="App-header">
      <h1 className="title">8bit Wokeys Marketplace</h1>
    </header>
  );
}

export default Header;